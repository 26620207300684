$(function () {
    $(document).ready(function () {
        $(".quicklinks-slider").each(function () {
            var thisElement = $(this);
            thisElement.slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: thisElement.closest('.quicklinks').find(
                    '.sliderPrevButton'),
                nextArrow: thisElement.closest('.quicklinks').find(
                    '.sliderNextButton'),
                mobileFirst: true,
                centerMode: true,
                responsive: [{
                    breakpoint: 450,
                    settings: {
                        centerMode: false,
                        slidesToShow: 2,
                    }
                }, {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        centerMode: false,
                    }
                }, ]
            });
        })
    });
});
$(function () {
    $(document).ready(function () {
        $(".flexibleblocks-block .slider-items").each(function () {
            var thisElement = $(this);
            thisElement.slick({
                fade: true,
                dots: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: thisElement.closest('.slider').find('.sliderPrevButton'),
                nextArrow: thisElement.closest('.slider').find('.sliderNextButton'),
                mobileFirst: true,
                responsive: [{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                }, ]
            });
        })
    });
});
$(function () {
    $(document).ready(function () {
        $(".slider-items").each(function () {
            var thisElement = $(this);
            thisElement.slick({
                fade: true,
                dots: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: thisElement.closest('.banner-slider').find('.sliderPrevButton'),
                nextArrow: thisElement.closest('.banner-slider').find('.sliderNextButton'),
                mobileFirst: true,
                autoplay: true,
                autoplaySpeed: 4000,
                responsive: [{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                }, ]
            });
        })
    });
});
$(function () {
    $(document).ready(function () {
        $(".research-cards").each(function () {
            var thisElement = $(this);
            thisElement.slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: false,
                nextArrow: false,
                mobileFirst: true,
                variableWidth: true,
                responsive: [{
                        breakpoint: 450,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                ]
            });
        })
    });
});
$(function () {
    $(document).ready(function () {
        $(".publications-slider").each(function () {
            var thisElement = $(this);
            thisElement.slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: thisElement.closest('.publications').find(
                    '.sliderPrevButton'),
                nextArrow: thisElement.closest('.publications').find(
                    '.sliderNextButton'),
                mobileFirst: true,
                responsive: [{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                    }
                }, ]
            });
        })
    });
});
$(document).ready(function () {
    $(".news-slider-slider").each(function () {
        var thisElement = $(this);
        thisElement.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: thisElement.closest('.news-slider').find('.sliderPrevButton'),
            nextArrow: thisElement.closest('.news-slider').find('.sliderNextButton'),
            mobileFirst: true,
            variableWidth: true,
            responsive: [{
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                    }
                },
            ]
        });
    })
    var progressBar = $('.progress');
    var progressBarLabel = $('.slider__label');
    $(".news-slider-slider").on("beforeChange", function (event, slick, currentSlide, nextSlide) {
        var calc = ((nextSlide) / (slick.slideCount - 1)) * 100;

        progressBar
            .css('background-size', calc + '% 100%')
            .attr('aria-valuenow', calc);

        progressBarLabel.text(calc + '% completed');
    })
})