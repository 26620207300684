import A11yDialog from 'a11y-dialog'
$('.modalContainer').each(function(){
    var id = $(this).attr('id'); // make sure your modalContainer element has a unique ID
    var container = document.getElementById(id)
    var dialog = new A11yDialog(container)
    dialog.on('show', function (element, event) {
        $('body').addClass('__modal')
        // Do something on show
    })
    dialog.on('hide', function (element, event) {
        $('body').removeClass('__modal')
        // Do something on hide
        
    })
})

