var videoElement = $('.localVideo');

videoElement.each(function() {
    $(this).magnificPopup({
        type: 'inline',
        callbacks: {
            open: function() {
                // Play video on open:
                if ($('.mfp-content').find('#video-element').length > 0) {
                    $('.mfp-content')
                        .find('#video-element')
                        .find('video')[0]
                        .play();
                }
            },
            close: function() {
                // Reset video on close:
                if ($('#video-element').length > 0) {
                    $('#video-element')
                        .find('video')[0]
                        .load();
                }
            }
        }
    });
});

objectFitPolyfill('.videoblock-local-popup video');
