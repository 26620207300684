//stop header being transparent on scroll
if ($(window).width() > 992) {
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var header = $('.header');
        if (scroll >= 100) {
            header.addClass('__active');
        }
        if (scroll < 100) {
            header.removeClass('__active');
        }
    });
}